import React, { Component } from 'react';
import { getSQLData, setSQLData } from '../../assets/functions/fetch';
import { matchFollowupsAndQuotes, matchQuotesAndData, matchQuotesAndEmails, matchQuotesAndLines, matchQuotesAndNotes, matchQuotesAndPOCosts } from '../../assets/functions/process/cam';
import { matchQuotesAndNotesv2, matchQuotesAndPOs, matchQuotesAndRFQs } from '../../assets/functions/process/quotes';
import moment from 'moment';

import { followupMetrics } from '../../assets/functions/process/followups';
import RepFollowups from '../tables/sales-operations/RepFollowups';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { CustomDateWrapper, Input } from '../styled/ui';

class SalesOperationsv2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rep: 8,
            updateList: [],
            selectedFollowup: -1,
            viewing: "Followups",
            minDate: new Date(new Date().setMonth(new Date().getMonth() - 10)),
            maxDate: new Date(new Date().setMonth(new Date().getMonth() - 9)),
            fuDate: new Date(new Date().setDate(new Date().getDate() + 1)).setHours(12, 0, 0, 0),
          
        };

        this.getPhaseColor = this.getPhaseColor.bind(this)
        this.updateRep = this.updateRep.bind(this)
        this.updateData = this.updateData.bind(this)
        this.saveFollowupChange = this.saveFollowupChange.bind(this)
        this.completeFollowup = this.completeFollowup.bind(this)
        this.selectFollowup = this.selectFollowup.bind(this)
      }

      componentDidMount() {
          this.fetchData();
        }
      
        fetchData = async () => {
            let rep = this.state.rep
      
          try {

            const fetchFollowups = await getSQLData("sales_operations/rep_followups", {nRep: rep})
            const fetchQuotes = await getSQLData("sales_operations/rep_followup_quotes", {nRep: rep})
            const fetchQuoteLines = await getSQLData("sales_operations/rep_followup_quote_lines", {nRep: rep})
            const fetchPOs = await getSQLData("sales_operations/rep_followup_POs", {nRep: rep})
            const fetchRFQs = await getSQLData("sales_operations/rep_followup_RFQs", {nRep: rep})
            const fetchNotes = await getSQLData("sales_operations/rep_followup_notes", {nRep: rep})
            //const fetchEmails = await getSQLData("sales_operations/rep_followup_emails", {nRep: rep})
            const fetchHistory = await getSQLData("sales_operations/customerHistory", {nRep: rep})
            
            if(fetchFollowups && fetchQuotes && fetchQuoteLines && fetchPOs && fetchRFQs && fetchNotes /*&& fetchEmails*/){
      
              let followups = fetchFollowups.data.data[0].recordset                        
              let quotes = fetchQuotes.data.data[0].recordset                        
              let quoteLines = fetchQuoteLines.data.data[0].recordset                        
              let POs = fetchPOs.data.data[0].recordset                        
              let RFQs = fetchRFQs.data.data[0].recordset                        
              let notes = fetchNotes.data.data[0].recordset                        
              //let emails = fetchEmails.data.data[0].recordset       
              let history = fetchHistory.data.data[0].recordset                 
              
              this.setState({
                followups: followups,
                quotes: quotes,
                quoteLines: quoteLines,
                POs: POs,
                RFQs: RFQs,
                notes: notes,
                //emails: emails,
                history: history,
              }, () => this.processData())
            }
               
          } catch (error) {
            console.error(error);
          }
        }
      
        processData() {
            let followups = this.state.followups
            let quotes = this.state.quotes
            let quoteLines = this.state.quoteLines
            let POs = this.state.POs
            let RFQs = this.state.RFQs
            let notes = this.state.notes
            //let emails = this.state.emails
            
            
            quotes = matchQuotesAndLines(quotes, quoteLines)
            quotes = matchQuotesAndPOs({quotes: quotes, POs: POs}).quotes
            quotes = matchQuotesAndRFQs({quotes: quotes, RFQs: RFQs}).quotes
            quotes = matchQuotesAndNotesv2({quotes: quotes, notes: notes}).quotes
            //quotes = matchQuotesAndEmails({quotes, emails}).quotes

            for(let i=0; i<quotes.length; i++){
                let quote = quotes[i]
                if (!quote.dtContactmade) {
                    quote.phase = "No contact made";
                } else {
                    quote.phase = "Needs info to quote";
                }
                
                if (quote.dtSentToCustomer) {
                    quote.phase = "DocuSign sent";
                }
                
                if (quote.dtFirstClosed) {
                    const now = moment(); 
                    const twoWeeksAgo = now.clone().subtract(14, 'days');
                    const oneWeekFromNow = now.clone().add(7, 'days');

                    const pickupDate = moment(quote.dtPickupDate);
                    const deliveryDate = moment(quote.dtDeliveryDate);

                    if (pickupDate.isBefore(twoWeeksAgo)) {
                        quote.phase = "Post rental follow up";
                    } else if (pickupDate.isAfter(twoWeeksAgo) && deliveryDate.isAfter(oneWeekFromNow)) {
                        quote.phase = "Rental logistics";
                    } else {
                        quote.phase = "DocuSign complete";
                    }

                }
                    
                
            }

            followups = matchFollowupsAndQuotes(followups, quotes)

            let metrics = followupMetrics(followups)
            
            this.setState({
                followups: followups,
                metrics: metrics,
                loaded: true,
            })
        }

        updateFollowup(index, value){
            let followups = this.state.followups
            let updateList = this.state.updateList

            followups[index].sNote = value
            let updateExists = updateList.find(x => x.id === followups[index].id)
            if(updateExists){
                updateExists.sNote = value
            } else {
                updateList.push(followups[index])
            }

            this.setState({
                followups: followups,
                updateList: updateList,
            })
        }

        updateCompleteNote(index, value){
            let followups = this.state.followups
            let updateList = this.state.updateList

            followups[index].sComplete = value
            let updateExists = updateList.find(x => x.id === followups[index].id)
            if(updateExists){
                updateExists.sComplete = value
            } else {
                updateList.push(followups[index])
            }

            this.setState({
                followups: followups,
                updateList: updateList,
            })
        }

        completeFollowup = async(index) => {
            let followups = this.state.followups
            let followup = followups[index]
            let leaveNote = false
            let nextFollowup = false

            if(followup.sNote && followup.sNote.length > 0 && followup.sNote != '') {
                leaveNote = true
            }

            if(followup.sNextFollowup){
                nextFollowup = true
            }

            try {
                const completeFollowup = await setSQLData("sales_operations/completeFollowup",{
                    id: followup.id,
                    nCompleted: 45,
                    nStatus: 1,
                })

                if(leaveNote){
                    const leaveNote = await setSQLData("sales_operations/leaveNote",{
                        nRep: 45,
                        nQuote: followup.nQuote,
                        sNote: (followup.sComplete.length > 0 ? followup.sComplete : "A followup was completed"),
                    })
                }

                if(nextFollowup){
                    const scheduleFollowup = await setSQLData("sales_operations/scheduleFollowup",{
                        nQuote: followups[index].nQuote,
                        nAdded: 45,
                        nRep: 8,
                        sNote: followups[index].sNextFollowup,
                        dtDate: moment(followups[index].dtNextDate).format("MM/DD/YYYY hh:mm A"),
                    })
                }

                
            } catch {

            }
        }

        saveFollowupChange = async (index) => {
            let followups = this.state.followups
            
            try {
                const updateFollowup = await setSQLData("sales_operations/updateFollowup",{
                    id: followups[index].id,
                    sNote: followups[index].sNote,
                    dtDate: moment(followups[index].dtDate).format("MM/DD/YYYY hh:mm A"),
                })

            } catch {

            }
        }

        updateData(index, value, type){
            let followups = this.state.followups
            let updateList = this.state.updateList

            let updateExists = updateList.find(x => x.id === followups[index].id)
            if(updateExists){
                switch(type){
                    case "date":
                        followups[index].dtDate = moment(value).format("YYYY-MM-DDTHH:mm")
                        updateExists.dtDate = moment(value).format("YYYY-MM-DDTHH:mm")
                        break;
                    case "followup":
                        followups[index].sNote = value
                        updateExists.sNote = value
                        break;
                    case "note":
                        followups[index].sComplete = value
                        updateExists.sComplete = value
                        break;
                    case "next date":
                        followups[index].dtNextDate = moment(value).format("YYYY-MM-DDTHH:mm")
                        updateExists.dtNextDate = moment(value).format("YYYY-MM-DDTHH:mm")
                        break;
                    case "next followup":
                        followups[index].sNextFollowup = value
                        updateExists.sNextFollowup = value
                        break;
                }
                
            } else {
                switch(type){
                    case "date":
                        followups[index].dtDate = moment(value).format("YYYY-MM-DDTHH:mm")
                        updateList.push(followups[index])
                        break;
                    case "followup":
                        followups[index].sNote = value
                        updateList.push(followups[index])
                        break;
                    case "note":
                        followups[index].sComplete = value
                        updateList.push(followups[index])
                        break;
                    case "next date":
                        followups[index].dtNextDate = moment(value).format("YYYY-MM-DDTHH:mm")
                        updateList.push(followups[index])
                        break;
                    case "next followup":
                        followups[index].sNextFollowup = value
                        updateList.push(followups[index])
                        break;
                }
                
            }

            this.setState({
                followups: followups,
                updateList: updateList,
            })
        }

        selectFollowup(index){
            this.setState({
                selectedFollowup: index,
            })
        }

        updateRep(rep){
            this.setState({rep: parseInt(rep)}, ()=>this.fetchData())
        }

        updateMinDate(value){
            let minDate = moment(value).format("YYYY-MM-DDTHH:mm")
            this.setState({minDate: minDate})
        }
        updateMaxDate(value){
            let maxDate = moment(value).format("YYYY-MM-DDTHH:mm")
            this.setState({maxDate: maxDate})
        }
        updateFUDate(value){
            let fuDate = moment(value).format("YYYY-MM-DDTHH:mm")
            this.setState({fuDate: fuDate})
        }

        createNewFollowup = async(quote, note) => {
            try {
                const scheduleFollowup = await setSQLData("sales_operations/scheduleFollowup",{
                    nQuote: quote,
                    nAdded: 45,
                    nRep: this.state.rep,
                    sNote: note,
                    dtDate: moment(this.state.fuDate).format("MM/DD/YYYY hh:mm A"),
                })
            } catch {

            }
        }

        getPhaseColor(phase){
            switch(phase){
                case "No contact made":
                    return "#D7E3EA"; // Soft Blue-Gray (Still neutral but fresher)
                    break;
                case "Needs info to quote":
                    return "#F0D89A"; // Warm Pastel Gold (More inviting)
                    break;
                case "DocuSign sent":
                    return "#BFD4E5"; // Light Sky Blue (Clear and professional)
                    break;
                case "Revision requested":
                    return "#E8B6A2"; // Soft Coral (Less dull, still not aggressive)
                    break;
                case "DocuSign complete":
                    return "#B7D9B5"; // Calming Green (Shows success but not too strong)
                    break;
                case "Rental logistics":
                    return "#C9B8DD"; // Muted Lilac (Slightly more color, still subtle)
                    break;
                case "Post rental follow up":
                    return "#A9D3C2"; // Soft Seafoam (A bit fresher than before)
                    break;
                default:
                    return "";
            }
            
            
            
        }
    render() {
        let followups = this.state.followups
        let updateList = this.state.updateList
        let loaded = this.state.loaded
        let selectedFollowupIndex = this.state.selectedFollowup
        let metrics = this.state.metrics
        let viewing = this.state.viewing
        let history = this.state.history
        let minDate = this.state.minDate
        let maxDate = this.state.maxDate
        let fuDate = this.state.fuDate
        let rep = this.state.rep

        return (
            <div>
                {loaded && (
                    <>
                        <button onClick={()=>this.setState({viewing: "Followups"})}>Followups</button>
                        <button onClick={()=>this.setState({viewing: "Customers"})}>Customers</button>
                        <div style={{display: 'inline-block'}}><select value={rep} onChange={(e)=>this.updateRep(e.target.value)}>
                            <option value={8}>Tony</option>
                            <option value={22}>Randy</option>
                            <option value={51}>Mark</option>
                        </select>
                        </div>
                        {viewing === "Followups" && (
                        
                        <RepFollowups 
                            followups={followups}
                            updateList={updateList}
                            selectedFollowupIndex={selectedFollowupIndex}
                            metrics={metrics}
                            getPhaseColor={this.getPhaseColor}
                            updateData={this.updateData}
                            saveFollowupChange={this.saveFollowupChange}
                            completeFollowup={this.completeFollowup}
                            selectFollowup={this.selectFollowup}
                        />
                        )}
                        {viewing === "Customers" && (
                            <>
                                Min Date <CustomDateWrapper>
                                    <Input
                                        onChange={(e) => {
                                        this.updateMinDate( e.target.value);
                                        }}
                                        type="datetime-local"
                                        value={minDate ? moment(minDate).format("YYYY-MM-DDTHH:mm") : ""}
                                        style={{width:'200px',}}
                                    />
                                </CustomDateWrapper>
                                Max Date <CustomDateWrapper>
                                    <Input
                                        onChange={(e) => {
                                        this.updateMaxDate( e.target.value);
                                        }}
                                        type="datetime-local"
                                        value={maxDate ? moment(maxDate).format("YYYY-MM-DDTHH:mm") : ""}
                                        style={{width:'200px',}}
                                    />
                                </CustomDateWrapper>
                                New FU Date <CustomDateWrapper>
                                    <Input
                                        onChange={(e) => {
                                        this.updateFUDate( e.target.value);
                                        }}
                                        type="datetime-local"
                                        value={fuDate ? moment(fuDate).format("YYYY-MM-DDTHH:mm") : ""}
                                        style={{width:'200px',}}
                                    />
                                </CustomDateWrapper>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Quote</th>
                                            <th>First Closed</th>
                                            <th>Last Discontinued</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    {history.map((customer, index)=>(
                                        <>
                                        
                                        {((!minDate || new Date(customer.dtFirstClosed) >= new Date(minDate)) &&
                                          (!maxDate || new Date(customer.dtFirstClosed) <= new Date(maxDate))) && (
                                            <tr>
                                                <td><Link target="_blank" to={`/quote/${customer.Quote}`}>{customer.Quote}</Link></td>
                                                <td>{customer.dtFirstClosed}</td>
                                                <td>{customer.MaxDiscontinuedDate}</td>
                                                <td>
                                                    <button onClick={
                                                        ()=>this.createNewFollowup(customer.Quote, 
                                                            "This followup was created because we last closed an order with this customer "
                                                            + moment(customer.dtFirstClosed).utc().format("MM/DD/YYYY")
                                                            + ", we have no open quotes, and no recently discontinued quotes.  "
                                                            + "If you have recently made contact with this customer, you may cancel this followup."
                                                        )
                                                        }>
                                                        Create New Followup
                                                    </button>
                                                </td>
                                            </tr>
                                        )}
                                        
                                        </>
                                    ))}
                                </table>
                            </>
                        )}
                    </>
                
                )}
            </div>
        );
    }
}

export default SalesOperationsv2;