import React, { Component } from "react";
import { getSQLData, setSQLData } from "../../../assets/functions/fetch";

class SalesRepSchedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scheduleEntries: [],
            loading: false,
            hasMore: true,
            offset: 0,
        };
    }

    componentDidMount() {
        this.fetchRepSchedule();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.rep !== this.props.rep) {
            this.setState({ scheduleEntries: [], offset: 0, hasMore: true }, () => {
                this.fetchRepSchedule();
            });
        }
    }

    fetchRepSchedule = async (loadMore = false) => {
        if (this.state.loading) return;
        this.setState({ loading: true });
        
        try {
            const response = await getSQLData("sales_operations/repScheduleData", {
                rep: this.props.rep,
                offset: this.state.offset
            });
            
            this.setState(prevState => ({
                scheduleEntries: loadMore ? [...prevState.scheduleEntries, ...response] : response,
                hasMore: response.length === 50, // If less than 50, no more data
                offset: prevState.offset + response.length,
            }));
        } catch (error) {
            console.error("Error fetching sales rep schedule:", error);
        }
        
        this.setState({ loading: false });
    };

    handleCheckboxChange = async (nCompany, field, value) => {
        try {
            await setSQLData("sales_operations/updateCompanyStatus", {
                nCompany,
                [field]: value
            });
            
            this.setState(prevState => ({
                scheduleEntries: prevState.scheduleEntries.map(entry => 
                    entry.nCompany === nCompany ? { ...entry, [field]: value } : entry
                )
            }));
        } catch (error) {
            console.error("Error updating company status:", error);
        }
    };

    render() {
        return (
            <div>
                <h2>Recent Follow-ups</h2>
                {this.state.scheduleEntries.length === 0 && !this.state.loading && <p>No recent follow-ups.</p>}
                {this.state.scheduleEntries.map((entry, index) => (
                    <div key={index} style={{ borderBottom: "1px solid #ccc", padding: "10px 0" }}>
                        <p><strong>Company:</strong> {entry.sCompany}</p>
                        <p><strong>Follow-up Note:</strong> {entry.scheduleNote}</p>
                        <p><strong>Completed On:</strong> {new Date(entry.dtCompleted).toLocaleString()}</p>
                        <p><strong>Most Recent Quote Note:</strong> {entry.recentQuoteNote || "No recent notes"}</p>
                        <label>
                            <input 
                                type="checkbox" 
                                checked={entry.bStopContact} 
                                onChange={() => this.handleCheckboxChange(entry.nCompany, "bStopContact", !entry.bStopContact)}
                            /> Stop Contact
                        </label>
                        <label style={{ marginLeft: "10px" }}>
                            <input 
                                type="checkbox" 
                                checked={entry.bGiveUp} 
                                onChange={() => this.handleCheckboxChange(entry.nCompany, "bGiveUp", !entry.bGiveUp)}
                            /> Give Up
                        </label>
                    </div>
                ))}
                {this.state.hasMore && !this.state.loading && (
                    <button onClick={() => this.fetchRepSchedule(true)}>Load More</button>
                )}
                {this.state.loading && <p>Loading...</p>}
            </div>
        );
    }
}

export default SalesRepSchedule;
