module.exports = {
    sales_operations_queries: {
        template: {
            query: "",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        FUReport: {
            query: `SELECT RS.nQuote, RS.dtDate, RS.dtCompleted, RS.nRep
FROM RAC_SCHEDULE RS
WHERE RS.bActive = 1
  AND RS.dtDate >= '2024-01-01'
  AND (
      RS.dtCompleted IS NULL  -- Keep all uncompleted entries
      OR EXISTS (  -- Check for a corresponding note only if dtCompleted is not NULL
          SELECT 1 
          FROM RAC_QUOTE_NOTES RQN
          WHERE RQN.nQuote = RS.nQuote
            AND RQN.nType = 1
            AND CAST(RQN.dtTimeStamp AS DATE) = CAST(RS.dtCompleted AS DATE)  -- Ensure same calendar day
      )
  )
ORDER BY RS.id DESC;

`,
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets a list of followups completed recently or still open.",
            tables: ["RAC_SCHEDULE"],
            type: "SELECT",
            whereUsed: [],
        },

            "repScheduleData": {
              "query": "SELECT TOP 50 rs.sNote AS scheduleNote, rs.dtCompleted, rcq.sCompany, " +
                       "c.bStopContact, c.bGiveUp, " +
                       "(SELECT TOP 1 rqn.sNote FROM RAC_QUOTE_NOTES rqn WHERE rqn.nQuote = rcq.id ORDER BY rqn.dtCreated DESC) AS recentQuoteNote " +
                       "FROM RAC_SCHEDULE rs " +
                       "JOIN RAC_CUSTOMER_QUOTES rcq ON rs.nQuote = rcq.id " +
                       "LEFT JOIN COMPANIES c ON rcq.nCompany = c.id " +
                       "WHERE rs.nRep = [rep] " +
                       "ORDER BY rs.dtCompleted DESC;",
              "req": ["rep"],
              "fields": [
                {"key": "scheduleNote", "label": "Schedule Note"},
                {"key": "dtCompleted", "label": "Date Completed"},
                {"key": "sCompany", "label": "Company Name"},
                {"key": "bStopContact", "label": "Stop Contact"},
                {"key": "bGiveUp", "label": "Give Up"},
                {"key": "recentQuoteNote", "label": "Recent Quote Note"}
              ],
              "desc": "Retrieves recently completed follow-ups for a sales rep, along with company and quote information.",
              "tables": ["RAC_SCHEDULE", "RAC_CUSTOMER_QUOTES", "COMPANIES", "RAC_QUOTE_NOTES"],
              "type": "SELECT",
              "whereUsed": ["Sales follow-up tracking", "Lead management"]
            },
            
                "updateCompanyStatus": {
                  "query": "UPDATE COMPANIES SET bStopContact = [bStopContact], bGiveUp = [bGiveUp] WHERE id = [nCompany];",
                  "req": ["nCompany", "bStopContact", "bGiveUp"],
                  "desc": "Updates the Stop Contact and Give Up status for a company.",
                  "tables": ["COMPANIES"],
                  "type": "UPDATE",
                  "whereUsed": ["Sales follow-up management", "Lead status updates"]
                },
            
                 
        
        noFUQuotes: {
            query: "SELECT id, nRep FROM RAC_CUSTOMER_QUOTES rcq WHERE sStatus='Open' and NOT bDeleted=1 AND (SELECT COUNT(id) FROM RAC_SCHEDULE rs WHERE rcq.id=rs.nQuote AND dtCompleted IS NULL) = 0",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Lists all quotes without FUs",
            tables: ["RAC_CUSTOMER_QUOTES", "RAC_SCHEDULE"],
            type: "SELECT",
            whereUsed: [],
        },
        sales_followup_query: {
            query: "SELECT * FROM (SELECT TOP 1000 * " + 
                        "FROM META_COMPANIES " + 
                        "WHERE last_sales_rep = [rep] " + 
                        "AND open_followup_count = 0 " + 
                        "AND last_followup_date < DATEADD(MONTH, -3, GETDATE()) " + 
                        "ORDER BY total_sales_amount DESC) AS subquery ORDER BY latest_delivery_date desc;",

            req: ["rep"],
            fields: [
                {key: "latest_followup_quote_id", label: "Latest Follow-up Quote ID"},
                {key: "latest_delivery_date", label: "Latest Delivery Date"}
            ],
            desc: "Retrieves the latest follow-up quote ID and latest delivery date for companies assigned to a specific sales rep, where there are no open follow-ups and the last follow-up was over 3 months ago. Results are ordered by total sales amount in descending order and limited to 10 rows.",
            tables: ["META_COMPANIES"],
            type: "SELECT",
            whereUsed: ["Sales follow-up reporting", "Account management", "Lead tracking"]
        },        
        customerHistory: {            
            query: "WITH LatestClosedQuotes AS ("
            + "SELECT q1.nCompany, q1.id, q1.dtFirstClosed, "
            + "(SELECT MAX(q2.dtLastUpdated) "
            + " FROM RAC_CUSTOMER_QUOTES q2 "
            + " WHERE q2.nCompany = q1.nCompany "
            + " AND q2.sStatus = 'Discontinued' "
            + " AND q2.nRep = [nRep]) AS MaxDiscontinuedDate "
            + "FROM RAC_CUSTOMER_QUOTES q1 "
            + "WHERE q1.sStatus = 'Closed' "
            + "AND q1.nRep = [nRep] "
            + "AND q1.dtFirstClosed >= DATEADD(YEAR, -7, GETDATE()) "
            + "AND NOT q1.bDiscontinuedValid = 1 "
            + "AND q1.id = ("
                + "SELECT MAX(q2.id) "
                + "FROM RAC_CUSTOMER_QUOTES q2 "
                + "WHERE q2.nCompany = q1.nCompany "
                + "AND q2.sStatus = 'Closed' "
                + "AND q2.nRep = [nRep] "
                + "AND q2.dtFirstClosed >= DATEADD(YEAR, -7, GETDATE()) "
                + "AND NOT q2.bDiscontinuedValid = 1"
            + ")"
        + ") "
        + "SELECT l.nCompany, l.id AS Quote, l.dtFirstClosed, l.MaxDiscontinuedDate "
        + "FROM LatestClosedQuotes l "
        + "LEFT JOIN ("
            + "SELECT nCompany, COUNT(*) AS OpenOrders "
            + "FROM RAC_CUSTOMER_QUOTES "
            + "WHERE sStatus = 'Open' "
            + "AND nRep = [nRep] "
            + "GROUP BY nCompany"
        + ") o ON l.nCompany = o.nCompany "
        + "LEFT JOIN ("
            + "SELECT nQuote, COUNT(*) AS PendingSchedules "
            + "FROM RAC_SCHEDULE "
            + "WHERE dtCompleted IS NULL "
            + "GROUP BY nQuote"
        + ") s ON l.id = s.nQuote "
        + "LEFT JOIN ("
            + "SELECT nQuote, COUNT(*) AS RecentCompleted "
            + "FROM RAC_SCHEDULE "
            + "WHERE dtCompleted >= DATEADD(MONTH, -3, GETDATE()) "
            + "GROUP BY nQuote"
        + ") r ON l.id = r.nQuote "
        + "WHERE COALESCE(o.OpenOrders, 0) = 0 "
        + "AND COALESCE(s.PendingSchedules, 0) = 0 "
        + "AND COALESCE(r.RecentCompleted, 0) = 0 "
        + "AND l.MaxDiscontinuedDate < DATEADD(MONTH, -2, GETDATE()) "
        + "ORDER BY l.dtFirstClosed DESC;",
            
            req: ["nRep"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets data about a rep's past customers.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "",
            whereUsed: [],
        },
        scheduleFollowup: {
            query: "INSERT INTO RAC_SCHEDULE (nQuote, nType, sNote, dtDate, nRep, dtAdded, nAdded, nStatus, nDuration) VALUES ([nQuote], 0, '[sNote]', '[dtDate]', [nRep], GETDATE(), [nAdded], 0, 0)",
            req: ["nQuote", "sNote", "dtDate", "nRep", "nAdded"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Creates a new followup",
            tables: ["RAC_SCHEDULE"],
            type: "INSERT INTO",
            whereUsed: [],
        },
        leaveNote: {
            query: "INSERT INTO RAC_QUOTE_NOTES (nQuote, sNote, nRep, nType, dtTimeStamp, nAttention, nAttentionPriority, nCategory) VALUES ([nQuote], '[sNote]', [nRep],1,GETDATE(),0,0,8)",
            req: ["sNote", "nRep", "nQuote"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Leaves a note",
            tables: ["RAC_QUOTE_NOTES"],
            type: "INSERT INTO",
            whereUsed: [],
        },
        completeFollowup: {
            query: "UPDATE RAC_SCHEDULE SET dtCompleted=GETDATE(), nCompleted=[nCompleted], nStatus=1 WHERE id=[id]",
            req: ["nCompleted", "id"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Completes a followup",
            tables: ["RAC_SCHEDULE"],
            type: "UPDATE",
            whereUsed: [],
        },
        updateFollowup: {
            query: "UPDATE RAC_SCHEDULE SET sNote='[sNote]', dtDate='[dtDate]' WHERE id=[id]",
            req: ["sNote", "dtDate", "id"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        rep_followups: {
            query: "SELECT * FROM RAC_SCHEDULE WHERE nRep=[nRep] AND dtCompleted IS NULL ORDER BY dtDate asc, nQuote desc",
            req: ["nRep"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Get all of a rep's followups",
            tables: ["RAC_SCHEDULE"],
            type: "SELECT",
            whereUsed: [],
        },
        rep_followup_quotes: {
            query: "SELECT rcq.*, "
                + "COALESCE(q.ClosedQuotes, 0) AS ClosedQuotes, "
                + "COALESCE(q.OpenQuotes, 0) AS OpenQuotes, "
                + "COALESCE(q.DiscontinuedQuotes, 0) AS DiscontinuedQuotes "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "JOIN RAC_SCHEDULE rs ON rcq.id = rs.nQuote "
                + "LEFT JOIN ( "
                + "    SELECT nCompany, "
                + "           COUNT(CASE WHEN sStatus = 'Closed' THEN 1 END) AS ClosedQuotes, "
                + "           COUNT(CASE WHEN sStatus = 'Open' THEN 1 END) AS OpenQuotes, "
                + "           COUNT(CASE WHEN sStatus = 'Discontinued' THEN 1 END) AS DiscontinuedQuotes "
                + "    FROM RAC_CUSTOMER_QUOTES "
                + "    GROUP BY nCompany "
                + ") q ON rcq.nCompany = q.nCompany "
                + "WHERE rs.nRep=[nRep] AND rs.dtCompleted IS NULL;",
            req: ["nRep"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Get all of a rep's followup quotes",
            tables: ["RAC_SCHEDULE"],
            type: "SELECT",
            whereUsed: [],
        },
        rep_followup_quote_lines: {
            query: "SELECT * FROM RAC_QUOTE_LINES WHERE nQuote IN (SELECT nQuote FROM RAC_SCHEDULE WHERE nRep=[nRep] AND dtCompleted IS NULL)",
            req: ["nRep"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Get all of a rep's followup quote lines",
            tables: ["RAC_SCHEDULE"],
            type: "SELECT",
            whereUsed: [],
        },
        rep_followup_POs: {
            query: "SELECT * FROM RAC_POS WHERE nAttachedQuote IN (SELECT nQuote FROM RAC_SCHEDULE WHERE nRep=[nRep] AND dtCompleted IS NULL)",
            req: ["nRep"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Get all of a rep's followup POs",
            tables: ["RAC_SCHEDULE"],
            type: "SELECT",
            whereUsed: [],
        },
        rep_followup_RFQs: {
            query: "SELECT * FROM RAC_RFQ WHERE nQuote IN (SELECT nQuote FROM RAC_SCHEDULE WHERE nRep=[nRep] AND dtCompleted IS NULL)",
            req: ["nRep"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Get all of a rep's followup RFQs",
            tables: ["RAC_SCHEDULE"],
            type: "SELECT",
            whereUsed: [],
        },
        rep_followup_notes: {
            query: "SELECT * FROM RAC_QUOTE_NOTES WHERE nQuote IN (SELECT nQuote FROM RAC_SCHEDULE WHERE nRep=[nRep] AND dtCompleted IS NULL)",
            req: ["nRep"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Get all of a rep's followup notes",
            tables: ["RAC_SCHEDULE"],
            type: "SELECT",
            whereUsed: [],
        },
        rep_followup_emails: {
            query: "SELECT * FROM RAC_QUOTE_EMAILS WHERE nQuote IN (SELECT nQuote FROM RAC_SCHEDULE WHERE nRep=[nRep] AND dtCompleted IS NULL)",
            req: ["nRep"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Get all of a rep's followup emails",
            tables: ["RAC_SCHEDULE"],
            type: "SELECT",
            whereUsed: [],
        },
    }
} 