import moment from 'moment';
import React, { Component } from 'react';
import { CustomDateWrapper, Input } from '../../styled/ui';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import { getPhaseColor } from '../../../assets/functions/display';

const PhaseBox = styled.div`
    display: inline-block;
    padding: 5px;
    margin: 5px;
    background-color: ${({ phase, hide }) => 
        hide && !hide[phase] ? getPhaseColor(phase) : ""};
    cursor: pointer;
`;

const phases = [
    "No contact made",
    "Needs info to quote",
    "DocuSign sent",
    "Revision requested",
    "DocuSign complete",
    "Rental logistics",
    "Rebill/Add-on",
    "Post rental follow up",
    
];

class RepFollowupsv2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewing: 'followup',
            hide: {},
        };

      }

      hidePhase(phase){
        let hide = this.state.hide

        hide[phase] = !hide[phase]

        this.setState({
            hide: hide,
        })
      }
      

    render() {
        let followups = this.props.followups
        let updateList = this.props.updateList
        let selectedFollowupIndex = this.props.selectedFollowupIndex
        let selectedFollowup = (selectedFollowupIndex >= 0) ? followups[selectedFollowupIndex] : {}
        let metrics = this.props.metrics

        let busDays2 = moment().utc().add(2, "days").endOf("day");
        let busDays3 = moment().utc().add(3, "days").endOf("day");
        let busDays4 = moment().utc().add(4, "days").endOf("day");
        let busDays5 = moment().utc().add(5, "days").endOf("day");

        let hide = this.state.hide

        return (


                <>
                <div>
                
                {phases.map(phase => (
                <PhaseBox 
                    key={phase}
                    phase={phase}
                    hide={hide}
                    onClick={() => this.hidePhase(phase)}
                >
                    {phase}
                </PhaseBox>
            ))}
                </div>
                    <div style={{ display: 'inline-block', width: '50%', border: '1px solid black', height: '800px', overflowY: 'auto', verticalAlign: 'top'}}>
                        {followups.map((followup, index) => (
                                <>
                                {!hide[followup.quote?.phase] && (
                                <div key={index} style={{ padding: '5px', border: '1px solid black', backgroundColor: getPhaseColor(followup.quote?.phase)  }} onClick={()=>this.props.selectFollowup(index)}>
                                    <div style={{ display: 'inline-block', width: '10%' }}><Link target="_blank" to={`/quote/${followup.nQuote}`}>
                                    {followup.nQuote}
                                    </Link></div>
                                <div style={{ display: 'inline-block', width: '30%' }}>
                                    {followup.quote?.sName} <br /> 
                                    {followup.quote?.sPhone} {followup.quote?.sEmail}
                                </div>
                                <div style={{ display: 'inline-block', width: '20%' }}>{followup.quote?.sCompany}</div>
                                <div 
                                    style={{ display: 'inline-block', width: '40%' }} 
                                    
                                    
                                    onClick={()=>navigator.clipboard.writeText(
                                        followup.sNote + " (" + 
                                        followup.quote?.sCompany + " - " + 
                                        followup.quote?.sName + ": " + 
                                        followup.quote?.sPhone + " " + 
                                        followup.quote?.sEmail + ") " + 
                                        "https://cc.cfrinc.us/quote.asp?id=" + followup.nQuote 
                                    )}
                                    
                                    >
                                    {moment(followup.dtDate).format("MM/DD/YYYY hh:mm A")} <br /> {followup.sNote}
                                </div>
                            </div>
                            )}
                            </>
                        ))}
                    </div>
                    <div style={{ display: 'inline-block', width: '50%', border: '1px solid black', verticalAlign: 'top' }}>
                        {selectedFollowupIndex === -1 && (
                            <div>
                                <table>
                                    <tr>
                                        <td>Followups</td>
                                        <td>{followups.length}</td>
                                    </tr>
                                    <tr>
                                        <td>Late</td>
                                        <td>{metrics.late}</td>
                                    </tr>
                                    <tr>
                                        <td>Today</td>
                                        <td>{metrics.today}</td>
                                    </tr>
                                    <tr>
                                        <td>Tomorrow</td>
                                        <td>{metrics.tomorrow}</td>
                                    </tr>
                                    <tr>
                                        <td>{busDays2.format("dddd")}</td>
                                        <td>{metrics.busDays2}</td>
                                    </tr>
                                    <tr>
                                        <td>{busDays3.format("dddd")}</td>
                                        <td>{metrics.busDays3}</td>
                                    </tr>
                                    <tr>
                                        <td>{busDays4.format("dddd")}</td>
                                        <td>{metrics.busDays4}</td>
                                    </tr>
                                    <tr>
                                        <td>{busDays5.format("dddd")}</td>
                                        <td>{metrics.busDays5}</td>
                                    </tr>
                                    <tr>
                                        <td>This Month</td>
                                        <td>{metrics.thisMonth}</td>
                                    </tr>
                                    <tr>
                                        <td>This Quarter</td>
                                        <td>{metrics.thisQuarter}</td>
                                    </tr>
                                    <tr>
                                        <td>This Year</td>
                                        <td>{metrics.thisYear}</td>
                                    </tr>
                                    <tr>
                                        <td>Next Year</td>
                                        <td>{metrics.nextYear}</td>
                                    </tr>
                                </table>
                            </div>
                        )}
                        {selectedFollowupIndex >= 0 && (
                            <div>
                                {/*<div style={{position: 'relative',}}>
                                    <button onClick={()=>this.setState({selectedFollowup: -1})}>X</button>
                                    <button onClick={()=>this.setState({viewing: 'followup'})}>Follow Up</button>
                                    <button onClick={()=>this.setState({viewing: 'quote'})}>Quote</button>
                                    <button onClick={()=>this.setState({viewing: 'notes'})}>Notes</button>
                                    <button onClick={()=>this.setState({viewing: 'emails'})}>Emails</button>
                                    <button onClick={()=>this.setState({viewing: 'vendor'})}>Vendor</button>
                                </div>
                                */}
                                <table>
                                    <tr style={{ verticalAlign: 'top',}}>
                                        <td>Followup</td>
                                        <td>
                                    <CustomDateWrapper>
                                        <Input
                                            onChange={(e) => {
                                            this.props.updateData(selectedFollowupIndex, e.target.value, "date");
                                            }}
                                            type="datetime-local"
                                            value={moment(selectedFollowup.dtDate).format("YYYY-MM-DDTHH:mm")}
                                            style={{width:'200px',}}
                                        />
                                    </CustomDateWrapper>
                                            
                                    <textarea style={{width: '200px', height:'100px',}} onChange={(e)=>this.props.updateData(selectedFollowupIndex, e.target.value, "followup")} value={selectedFollowup.sNote} />

                                    
                                    </td>
                                    <td>Note</td>
                                        <td>
                                            <textarea style={{width: '200px', height:'125px',}} onChange={(e)=>this.props.updateData(selectedFollowupIndex, e.target.value, "note")} value={selectedFollowup.sComplete} />

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Next Followup<br /> (leave blank if none)</td>
                                        <td>
                                        <CustomDateWrapper>
                                            <Input
                                                onChange={(e) => {
                                                this.props.updateData(selectedFollowupIndex, e.target.value, "next date");
                                                }}
                                                type="datetime-local"
                                                value={selectedFollowup.dtNextDate ? moment(selectedFollowup.dtNextDate).format("YYYY-MM-DDTHH:mm") : ""}
                                                style={{width:'200px',}}
                                            />
                                        </CustomDateWrapper>
                                        
                                <textarea style={{width: '200px', height:'100px',}} onChange={(e)=>this.props.updateData(selectedFollowupIndex, e.target.value, "next followup")} value={selectedFollowup.sNextFollowup}/>
                                
                                        </td>
                                        <td>
                                            <div><button><a href={"https://cc.cfrinc.us/quote.asp?id="+selectedFollowup.nQuote}target="_blank" rel="noopener noreferrer">
                                                Go to Quote
                                                </a></button></div>
                                                <br />
                                                <div>Delivery: {moment(selectedFollowup?.quote?.dtDeliveryDate).utc().format("MM/DD/YYYY")}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                        <b>
                                            <button
                                                style={{ margin: '5px', padding: '5px', width: '100px' }}
                                                onClick={(e) => this.props.completeFollowup(selectedFollowupIndex)}
                                                disabled={this.props.processing} // Disable button while processing
                                            >
                                                {this.props.processing ? "Processing..." : "Complete Followup"}
                                            </button>
                                            <br />
                                        </b>

                                            {updateList.some(x => x.id === selectedFollowup.id) && <button style={{margin: '5px', padding: '5px', width: '100px'}} onClick={()=>this.props.saveFollowupChange(selectedFollowupIndex)}>Update Followup</button>}
                                            {/*
                                            <button style={{margin: '5px', padding: '5px', width: '100px'}} onClick={(e)=>this.props.completeFollowup(selectedFollowupIndex)}><i class='fas fa-clock' />&nbsp;0-5m</button>
                                            <button style={{margin: '5px', padding: '5px', width: '100px'}} onClick={(e)=>this.props.completeFollowup(selectedFollowupIndex)}><i class='fas fa-clock' />&nbsp;5-15m</button>
                                            <button style={{margin: '5px', padding: '5px', width: '100px'}} onClick={(e)=>this.props.completeFollowup(selectedFollowupIndex)}><i class='fas fa-clock' />&nbsp;15m-1h</button>
                                            <button style={{margin: '5px', padding: '5px', width: '100px'}} onClick={(e)=>this.props.completeFollowup(selectedFollowupIndex)}><i class='fas fa-clock' />&nbsp;1-2h</button>
                                            <button style={{margin: '5px', padding: '5px', width: '100px'}} onClick={(e)=>this.props.completeFollowup(selectedFollowupIndex)}><i class='fas fa-clock' />&nbsp;2h+</button>
                                            */}
                                        </td>
                                    </tr>
                                </table>
                              
                            </div>
                        )}
                    </div>

                </>
        );
    }
}

export default RepFollowupsv2;