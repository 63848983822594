import { useEffect, useState } from "react";
import { getSQLData, setSQLData } from "../../../assets/functions/fetch";
import moment from "moment";

const QuotesWithNoFUs = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clickedButtons, setClickedButtons] = useState({}); // Track clicked buttons

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await getSQLData("sales_operations/noFUQuotes");
        const quotesData = response?.data?.data?.[0]?.recordset || [];
        setData(quotesData);
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  const createFollowUp = async (quoteId, repId) => {
    try {
      await setSQLData("sales_operations/scheduleFollowup", {
        nQuote: quoteId,
        nAdded: props.user,
        nRep: repId,
        sNote: "This followup was created because there wasn't one.",
        dtDate: moment().format("MM/DD/YYYY hh:mm A"),
      });

      setClickedButtons((prev) => ({
        ...prev,
        [quoteId]: "Follow-up Scheduled",
      }));

      setTimeout(() => {
        setData((prevData) => prevData.filter((quote) => quote.id !== quoteId));
      }, 1000); // Remove the row after a delay
    } catch (error) {
      console.error("Error scheduling follow-up:", error);
      setClickedButtons((prev) => ({
        ...prev,
        [quoteId]: "Error! Try Again",
      }));

      setTimeout(() => {
        setClickedButtons((prev) => {
          const newState = { ...prev };
          delete newState[quoteId];
          return newState;
        });
      }, 2000); // Remove error message after delay
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Quotes with No Follow-Ups</h1>
      <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}>
        <thead>
          <tr style={{ backgroundColor: "#f2f2f2", textAlign: "left" }}>
            <th style={{ padding: "10px", borderBottom: "2px solid #ddd" }}>Quote ID</th>
            <th style={{ padding: "10px", borderBottom: "2px solid #ddd" }}>Rep ID</th>
            <th style={{ padding: "10px", borderBottom: "2px solid #ddd" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((quote) => (
            <tr key={quote.id} style={{ borderBottom: "1px solid #ddd" }}>
              <td style={{ padding: "10px" }}>{quote.id}</td>
              <td style={{ padding: "10px" }}>{quote.nRep}</td>
              <td style={{ padding: "10px" }}>
                {clickedButtons[quote.id] ? (
                  <span style={{ color: clickedButtons[quote.id] === "Error! Try Again" ? "red" : "green" }}>
                    {clickedButtons[quote.id]}
                  </span>
                ) : (
                  <button
                    onClick={() => createFollowUp(quote.id, quote.nRep)}
                    style={{
                      padding: "5px 10px",
                      backgroundColor: "#007bff",
                      color: "#fff",
                      border: "none",
                      borderRadius: "3px",
                      cursor: "pointer",
                    }}
                  >
                    Create Follow-up
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default QuotesWithNoFUs;