import React, { Component } from 'react';
import { getSQLData, setSQLData } from '../../assets/functions/fetch';
import { matchFollowupsAndQuotes, } from '../../assets/functions/process/cam';
import moment from 'moment';
import { followupMetrics } from '../../assets/functions/process/followups';
import RepFollowupsv2 from '../tables/sales-operations/RepFollowupsv2';
import { assignPhase } from '../../assets/functions/quote';
import FollowUpCustomers from '../tables/sales-operations/FollowUpCustomers';
import QuotesWithNoFUs from '../tables/sales-operations/QuotesWithNoFUs';
import RecentlyCompletedFUs from '../tables/sales-operations/SalesRepSchedule';
import SalesRepSchedule from '../tables/sales-operations/SalesRepSchedule';
import FollowUpReport from '../tables/sales-operations/FollowUpReport';

const RepSelector = ({ reps, onSelect, selectedRep }) => (
    <div style={{ marginBottom: '10px' }}>
        <label style={{ fontWeight: 'bold', marginRight: '5px' }}>Select Rep:</label>
        <select 
            onChange={(e) => onSelect(e.target.value)} 
            value={selectedRep} 
            style={{ padding: '5px', borderRadius: '5px', border: '1px solid #ccc' }}
        >
            <option value="">Select a Rep</option>
            {reps.map(rep => (
                <option key={rep.id} value={rep.id}>{rep.name}</option>
            ))}
        </select>
    </div>
);


class SalesOperationsv3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rep: this.props.user,
            updateList: [],
            selectedFollowup: -1,
            viewing: "Followups",
            minDate: new Date(new Date().setMonth(new Date().getMonth() - 10)),
            maxDate: new Date(new Date().setMonth(new Date().getMonth() - 9)),
            fuDate: new Date(new Date().setDate(new Date().getDate() + 1)).setHours(12, 0, 0, 0),
        };

        this.updateRep = this.updateRep.bind(this)
        this.updateData = this.updateData.bind(this)
        this.saveFollowupChange = this.saveFollowupChange.bind(this)
        this.completeFollowup = this.completeFollowup.bind(this)
        this.selectFollowup = this.selectFollowup.bind(this)
      }

        componentDidMount() {
            this.startTime = performance.now(); 
            this.fetchData();
        }
      
        fetchData = async () => {
            let rep = this.state.rep
      
          try {

            const fetchFollowups = await getSQLData("sales_operations/rep_followups", {nRep: rep})
            const fetchQuotes = await getSQLData("sales_operations/rep_followup_quotes", {nRep: rep})
            
            if(fetchFollowups && fetchQuotes){
      
              let followups = fetchFollowups.data.data[0].recordset                        
              let quotes = fetchQuotes.data.data[0].recordset           
              
              this.setState({
                followups: followups,
                quotes: quotes,
                updateList: [],
                selectedFollowup: -1,
              }, () => this.processData())
            }
               
          } catch (error) {
            console.error(error);
          }
        }


        processData() {
            console.log(performance.now() - this.startTime);

            const { followups, quotes } = this.state;

            quotes.forEach(quote => {
                quote.phase = assignPhase(quote);
            });

            // Process followups and metrics after loop execution
            const updatedFollowups = matchFollowupsAndQuotes(followups, quotes);
            const metrics = followupMetrics(updatedFollowups);

            // Batch state updates to reduce re-render overhead
            this.setState({
                followups: updatedFollowups,
                metrics,
                loaded: true,
                processing: false,
            }, () => console.log(performance.now() - this.startTime));
        }


        updateFollowup(index, value){
            let followups = this.state.followups
            let updateList = this.state.updateList

            followups[index].sNote = value
            let updateExists = updateList.find(x => x.id === followups[index].id)
            if(updateExists){
                updateExists.sNote = value
            } else {
                updateList.push(followups[index])
            }

            this.setState({
                followups: followups,
                updateList: updateList,
            })
        }

        updateCompleteNote(index, value){
            let followups = this.state.followups
            let updateList = this.state.updateList

            followups[index].sComplete = value
            let updateExists = updateList.find(x => x.id === followups[index].id)
            if(updateExists){
                updateExists.sComplete = value
            } else {
                updateList.push(followups[index])
            }

            this.setState({
                followups: followups,
                updateList: updateList,
            })
        }

        completeFollowup = async(index) => {
            let followups = this.state.followups
            let followup = followups[index]
            let leaveNote = false
            let nextFollowup = false
            this.setState({processing: true,})

            if(followup.sNote && followup.sNote.length > 0 && followup.sNote != '') {
                leaveNote = true
            }

            if(followup.sNextFollowup){
                nextFollowup = true
            }

            try {
                // Complete follow-up
                await setSQLData("sales_operations/completeFollowup", {
                    id: followup.id,
                    nCompleted: this.state.rep,
                    nStatus: 1,
                });
            
                // Leave a note if required
                if (leaveNote) {
                    await setSQLData("sales_operations/leaveNote", {
                        nRep: this.state.rep,
                        nQuote: followup.nQuote,
                        sNote: followup.sComplete.length > 0 ? followup.sComplete : "A followup was completed",
                    });
                }
            
                // Schedule the next follow-up if required
                if (nextFollowup) {
                    await setSQLData("sales_operations/scheduleFollowup", {
                        nQuote: followups[index].nQuote,
                        nAdded: this.state.rep,
                        nRep: this.state.rep,
                        sNote: followups[index].sNextFollowup,
                        dtDate: moment(followups[index].dtNextDate).format("MM/DD/YYYY hh:mm A"),
                    });
                }
                // Fetch updated data **after** all processes complete
                this.fetchData();
            
            } catch (error) {
                console.error("Error in follow-up process:", error);
            }
            
        }

        saveFollowupChange = async (index) => {
            let followups = this.state.followups
            
            try {
                const updateFollowup = await setSQLData("sales_operations/updateFollowup",{
                    id: followups[index].id,
                    sNote: followups[index].sNote,
                    dtDate: moment(followups[index].dtDate).format("MM/DD/YYYY hh:mm A"),
                })

            } catch {

            }
        }

        updateData(index, value, type) {
            const { followups, updateList } = this.state;
            const formattedValue = ["date", "next date"].includes(type) ? moment(value).format("YYYY-MM-DDTHH:mm") : value;
        
            const followup = { ...followups[index] };
            const updateExists = updateList.find(x => x.id === followup.id);
        
            const fieldMap = {
                "date": "dtDate",
                "followup": "sNote",
                "note": "sComplete",
                "next date": "dtNextDate",
                "next followup": "sNextFollowup"
            };
        
            if (fieldMap[type]) {
                followup[fieldMap[type]] = formattedValue;
                if (updateExists) {
                    updateExists[fieldMap[type]] = formattedValue;
                } else {
                    updateList.push(followup);
                }
            }
        
            followups[index] = followup;
        
            this.setState({ followups, updateList });
        }
        

        selectFollowup(index){
            this.setState({
                selectedFollowup: index,
            })
        }

        updateRep(rep){
            this.setState({rep: parseInt(rep)}, ()=>this.fetchData())
        }

        updateMinDate(value){
            let minDate = moment(value).format("YYYY-MM-DDTHH:mm")
            this.setState({minDate: minDate})
        }
        updateMaxDate(value){
            let maxDate = moment(value).format("YYYY-MM-DDTHH:mm")
            this.setState({maxDate: maxDate})
        }
        updateFUDate(value){
            let fuDate = moment(value).format("YYYY-MM-DDTHH:mm")
            this.setState({fuDate: fuDate})
        }

        createNewFollowup = async(quote, note) => {
            try {
                const scheduleFollowup = await setSQLData("sales_operations/scheduleFollowup",{
                    nQuote: quote,
                    nAdded: 45,
                    nRep: this.state.rep,
                    sNote: note,
                    dtDate: moment(this.state.fuDate).format("MM/DD/YYYY hh:mm A"),
                })
            } catch {

            }
        }

        changeRepAndFetchData = (newRep) => {
            this.setState({ rep: parseInt(newRep) }, this.fetchData);
        }
        
        
    render() {
        let followups = this.state.followups
        let updateList = this.state.updateList
        let loaded = this.state.loaded
        let selectedFollowupIndex = this.state.selectedFollowup
        let metrics = this.state.metrics
        let viewing = this.state.viewing
        let rep = this.state.rep
        let user = this.props.user
        let processing = this.state.processing

        return (
            <div>
                {loaded && (
                    <>
                        {(user === 45 || user === 54 || user === 5) && (
                            <RepSelector 
                            reps={[
                                { id: 8, name: "Tony" },
                                { id: 22, name: "Randy" },
                                { id: 51, name: "Mark" },
                                { id: 64, name: "Jeff" },
                                { id: 73, name: "Greg" },
                                { id: 63, name: "Hunter" },
                                { id: 44, name: "KB" },
                                { id: 68, name: "KT" },
                                
                            ]}
                            selectedRep={rep}
                            onSelect={(rep) => this.changeRepAndFetchData(rep)}
                            />
                        )}
                        <button onClick={() => this.setState({ viewing: "Followups" })}>Followups</button>
                        <button onClick={() => this.setState({ viewing: "FollowUpCustomers" })}>FollowUp Customers</button>
                        {user === 45 && (<button onClick={() => this.setState({ viewing: "No FUs" })}>No FUs</button>)}
                        <button onClick={() => this.setState({ viewing: "FU Report" })}>FU Report</button>
                        {/*<button onClick={() => this.setState({ viewing: "Recently Completed" })}>Recently Completed</button>*/}

                        {viewing === "Followups" && (
                        <RepFollowupsv2 
                            followups={followups}
                            updateList={updateList}
                            selectedFollowupIndex={selectedFollowupIndex}
                            metrics={metrics}
                            updateData={this.updateData}
                            saveFollowupChange={this.saveFollowupChange}
                            completeFollowup={this.completeFollowup}
                            selectFollowup={this.selectFollowup}
                            processing={processing}
                        />
                        )}

                        {viewing === "FollowUpCustomers" && (
                            <FollowUpCustomers 
                                rep={rep}
                            />
                        )}
                        {viewing === "No FUs" && (
                            <QuotesWithNoFUs 
                                user={this.props.user}
                            />
                        )}
                        {viewing === "Recently Completed" && (
                            <SalesRepSchedule 
                                rep={rep}
                            />
                        )}
                        {viewing === "FU Report" && (
                            <FollowUpReport 
                            />
                        )}
                    </>
                )}

            </div>
        );
    }
}

export default SalesOperationsv3;