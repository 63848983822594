import moment from "moment";

export function calcQuoteLinePOCost(quoteLines, quotePOLines){
    for(let i=0; i<quoteLines.length; i++){
        quoteLines[i].POCost = 0
    }
    for(let i=0 ; i<quotePOLines.length; i++){
        if(quotePOLines[i].nQuoteLine){
          let POLine = quotePOLines[i]
          let line = quoteLines.find((quoteLine) => quoteLine.id === POLine.nQuoteLine)
          line.POCost = line.POCost ? line.POCost + POLine.nPrice * POLine.nQuantity : POLine.nPrice * POLine.nQuantity
        }
      }
    for(let i=0; i<quoteLines.length; i++){
        quoteLines[i].POCost = (quoteLines[i].POCost ? quoteLines[i].POCost : 0)
        quoteLines[i].Margin = quoteLines[i].nQuantity*quoteLines[i].nPrice - quoteLines[i].POCost
    }

    return quoteLines
}

export function returnUnassignedPOLines(quotePOLines){
    let unassignedPOLines = []
    for(let i=0 ; i<quotePOLines.length; i++){
        if(!quotePOLines[i].nQuoteLine){
            quotePOLines[i].index = i
            unassignedPOLines.push(quotePOLines[i])
        }
    }
    return unassignedPOLines
}

export function returnAssignedPOLines(quotePOLines, quoteLine){
    let assignedPOLines = []
    if(quotePOLines && quoteLine){
        for(let i=0; i<quotePOLines.length; i++){
            let POLine = quotePOLines[i]
            if(POLine.nQuoteLine === quoteLine.id){
                quotePOLines[i].index = i
                assignedPOLines.push(POLine)
            }
        }
    }
    
    return assignedPOLines
}



export function assignPhase(quote) {
    const now = moment();
    const twoWeeksAgo = now.clone().subtract(14, 'days');
    const oneWeekFromNow = now.clone().add(7, 'days');
    if (quote.dtFirstClosed) {
        
        const pickupDate = moment(quote.dtPickupDate);
        const deliveryDate = moment(quote.dtDeliveryDate);

        if (pickupDate.isBefore(oneWeekFromNow)) {
            return "Post rental follow up";
        }
        if (pickupDate.isAfter(oneWeekFromNow) && deliveryDate.isAfter(twoWeeksAgo)) {
            return "DocuSign complete";
        }
        return "Rental logistics";
    }
    if (quote.nParentQuote) {
        return "Rebill/Add-on";
    }
    if (!quote.dtContactmade) {
        return "No contact made";
    }
    if (quote.dtSentToCustomer) {
        return "DocuSign sent";
    }
    
    
    return "Needs info to quote";
}
