module.exports = {
    meta_queries: {
        template: {
            query: "",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        updateMetaCompanies: {
            
            query: "DELETE FROM META_COMPANIES; "  
            + "INSERT INTO META_COMPANIES (id, last_followup_date, open_quote_count, latest_followup_quote_id, total_sales_amount, open_followup_count, last_sales_rep, latest_delivery_date) "
            + "SELECT q.nCompany AS id, "
            + " MAX(rs.dtCompleted) AS last_followup_date, "
            + " SUM(CASE WHEN q.sStatus = 'Open' AND q.bDeleted = 0 THEN 1 ELSE 0 END) AS open_quote_count, "
            + " (SELECT TOP 1 q2.id FROM RAC_CUSTOMER_QUOTES q2 WHERE q2.nCompany = q.nCompany ORDER BY q2.dtDateStamp DESC) AS latest_followup_quote_id, "
            + " (SELECT SUM(q3.nQuoteTotal) FROM RAC_CUSTOMER_QUOTES q3 WHERE q3.nCompany = q.nCompany AND q3.sStatus = 'Closed') AS total_sales_amount, "
            + " SUM(CASE WHEN rs.dtCompleted IS NULL THEN 1 ELSE 0 END) AS open_followup_count, "
            + " (SELECT TOP 1 q2.nRep FROM RAC_CUSTOMER_QUOTES q2 WHERE q2.nCompany = q.nCompany ORDER BY q2.dtDateStamp DESC) AS last_sales_rep, "
            + " (SELECT MAX(q4.dtDeliveryDate) FROM RAC_CUSTOMER_QUOTES q4 WHERE q4.nCompany = q.nCompany) AS latest_delivery_date "
            + "FROM RAC_CUSTOMER_QUOTES q "
            + "LEFT JOIN RAC_SCHEDULE rs ON rs.nQuote = q.id "
            + "WHERE q.nCompany IS NOT NULL "  
            + "GROUP BY q.nCompany;",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Updates META_COMPANIES",
            tables: ["RAC_CUSTOMER_QUOTES", "RAC_SCHEUDLE","META_COMPANIES"],
            type: "",
            whereUsed: [],
        },
    }
} 