import { useEffect, useState } from "react";
import { getSQLData, setSQLData } from "../../../assets/functions/fetch";
import moment from "moment";
import { nQuoteTotalNoDollar } from "../../../assets/functions/calculations";

const FollowUpCustomers = ({ rep }) => {
  const [data, setData] = useState([]);
  const [processedData, setProcessedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hiddenButtons, setHiddenButtons] = useState({});
  const [fuDate, setFuDate] = useState(moment().format("YYYY-MM-DDTHH:mm")); // Default to now

  useEffect(() => {
    const loadData = async () => {
      try {
        const fetchFollowups = await getSQLData("sales_operations/sales_followup_query", { rep });

        // Extract data safely
        const rawData = fetchFollowups?.data?.data?.[0]?.recordset || [];

        setData(rawData);

        const processed = await processData(rawData);
        setProcessedData(processed);
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (rep) loadData();
  }, [rep]);

  const createNewFollowup = async (quote, note) => {
    if (!quote) {
      console.error("No quote ID available for follow-up.");
      return;
    }

    try {
      await setSQLData("sales_operations/scheduleFollowup", {
        nQuote: quote,
        nAdded: 45,
        nRep: rep, // Using rep from props
        sNote: note,
        dtDate: moment(fuDate).format("MM/DD/YYYY hh:mm A"), // Use selected follow-up date
      });
      console.log("Follow-up successfully scheduled for Quote:", quote);
      setHiddenButtons((prev) => ({ ...prev, [quote]: true })); // Hide button after click
    } catch (error) {
      console.error("Error scheduling follow-up:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Customers to Follow Up</h1>

      <div style={{ marginBottom: "20px" }}>
        <label htmlFor="fuDate"><strong>Select Follow-up Date:</strong></label>
        <input 
          type="datetime-local" 
          id="fuDate" 
          value={fuDate} 
          onChange={(e) => setFuDate(e.target.value)} 
          style={{ marginLeft: "10px", padding: "5px" }}
        />
      </div>

      <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}>
        <thead>
          <tr style={{ backgroundColor: "#f2f2f2", textAlign: "left" }}>
            <th style={{ padding: "10px", borderBottom: "2px solid #ddd" }}>Last Delivery Date</th>
            <th style={{ padding: "10px", borderBottom: "2px solid #ddd" }}>Last Followup Date</th>
            <th style={{ padding: "10px", borderBottom: "2px solid #ddd" }}>Sales</th>
            <th style={{ padding: "10px", borderBottom: "2px solid #ddd" }}>Quote</th>
            <th style={{ padding: "10px", borderBottom: "2px solid #ddd" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {processedData.map((customer, index) => (
            <tr key={index} style={{ borderBottom: "1px solid #ddd" }}>
              
              <td style={{ padding: "10px" }}>
                {customer.latest_delivery_date ? moment(customer.latest_delivery_date).format("MM/DD/YYYY") : "N/A"}
              </td>
              <td style={{ padding: "10px" }}>
                {customer.last_followup_date ? moment(customer.last_followup_date).format("MM/DD/YYYY") : "N/A"}
              </td>
              <td style={{ padding: "10px" }}>
                {customer.total_sales_amount ? nQuoteTotalNoDollar(customer.total_sales_amount) : "N/A"}
              </td>
              <td style={{ padding: "10px" }}>
                                            
                {customer.latest_followup_quote_id ? (
                  <a href={"https://cc.cfrinc.us/quote.asp?id="+customer.latest_followup_quote_id}target="_blank" rel="noopener noreferrer">
                      Go to Quote {customer.latest_followup_quote_id}
                    </a>
                  ) : "N/A"}
              </td>
              <td style={{ padding: "10px" }}>
                {!hiddenButtons[customer.latest_followup_quote_id] && (
                  <button 
                    onClick={() => createNewFollowup(
                      customer.latest_followup_quote_id,
                      "Last delivery " +
                      moment(customer.latest_delivery_date).utc().format("MM/DD/YYYY") +
                      " no open followups. "
                    )}
                    style={{ padding: "5px 10px", backgroundColor: "#007bff", color: "#fff", border: "none", borderRadius: "3px", cursor: "pointer" }}
                  >
                    Create Follow-up
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const processData = async (data) => {
  return new Promise((resolve) =>
    setTimeout(() => resolve(data.map((item) => ({ ...item, processed: true }))), 1000)
  );
};

export default FollowUpCustomers;
