import moment from "moment";
import { whichRep } from "../../../assets/functions/calculations";

// Function to initialize metrics for a rep
export const initializeRepMetrics = (repId) => ({
    repName: whichRep(repId),
    followups: {
      completed: {
        today: 0,
        day_1_ago: 0,
        day_2_ago: 0,
        day_3_ago: 0,
        day_4_ago: 0,
        day_5_ago: 0,
        day_6_ago: 0,
        day_7_ago: 0,
      },
      outstanding: {
        past_due: 0,
        due_today: 0,
        due_next_week: 0,
        due_next_month: 0,
        due_next_year: 0,
        due_beyond_next_year: 0,
      },
    },
  });
  
  // Function to process a single follow-up entry
  export const processFollowUp = (fu, metrics) => {
    if (!fu.nRep) return; // Skip if no rep assigned
  
    if (!metrics[fu.nRep]) {
      metrics[fu.nRep] = initializeRepMetrics(fu.nRep);
    }

  // Convert dates using moment.js
  const dtCompleted = fu.dtCompleted ? moment(fu.dtCompleted) : null;
  const dtDate = moment(fu.dtDate);
  const today = moment().startOf("day");
  const daysAgo = dtCompleted ? today.diff(dtCompleted.startOf("day"), "days") : null;


  if (dtCompleted) {
    // Completed follow-up: Check if it's within the last 7 days
    if (daysAgo === 0) metrics[fu.nRep].followups.completed.today++;
    else if (daysAgo <= 7) metrics[fu.nRep].followups.completed[`day_${daysAgo}_ago`]++;
  } else {
    // Outstanding follow-up: Categorize by due date
    if (dtDate.isBefore(today)) {
      metrics[fu.nRep].followups.outstanding.past_due++; // Past due
    } else if (dtDate.isSame(today, "day")) {
      metrics[fu.nRep].followups.outstanding.due_today++; // Due today
    } else if (dtDate.isBefore(moment().add(7, "days"))) {
      metrics[fu.nRep].followups.outstanding.due_next_week++; // Due in next 7 days
    } else if (dtDate.isBefore(moment().add(30, "days"))) {
      metrics[fu.nRep].followups.outstanding.due_next_month++; // Due in next 30 days
    } else if (dtDate.isBefore(moment().add(1, "year"))) {
      metrics[fu.nRep].followups.outstanding.due_next_year++; // Due within a year
    } else {
      metrics[fu.nRep].followups.outstanding.due_beyond_next_year++; // Due after 1 year
    }
  }
};

// Main function to process all follow-ups
export const processFollowUpsData = (followups) => {
    const metrics = {};
  
    followups.forEach((fu) => processFollowUp(fu, metrics));
  
    return metrics;
  };

  // Groups reps in the desired order
  export const getOrderedRepIds = (metrics) => {
    const priorityReps = ["8", "22", "51"];
    const secondaryReps = ["64", "73"];
    const techReps = ["44", "63" , "68", "74"];
    const allowedOtherReps = ["5", "40", "54",  "71"];

    const otherReps = Object.keys(metrics)
        .filter(repId => allowedOtherReps.includes(repId)) // Only include specified "other reps"
        .sort((a, b) => a - b); // Sort numerically

    return [...priorityReps, ...secondaryReps, ...techReps, ...otherReps];
};


// List of metric categories
export const getMetricRows = () => [
    { key: "completed.today", label: "Completed Today" },
    { key: "completed.day_1_ago", label: "Completed 1 Day Ago" },
    { key: "completed.day_2_ago", label: "Completed 2 Days Ago" },
    { key: "completed.day_3_ago", label: "Completed 3 Days Ago" },
    { key: "completed.day_4_ago", label: "Completed 4 Days Ago" },
    { key: "completed.day_5_ago", label: "Completed 5 Days Ago" },
    { key: "completed.day_6_ago", label: "Completed 6 Days Ago" },
    { key: "completed.day_7_ago", label: "Completed 7 Days Ago" },
    { key: "outstanding.past_due", label: "Past Due" },
    { key: "outstanding.due_today", label: "Due Today" },
    { key: "outstanding.due_next_week", label: "Due Next Week" },
    { key: "outstanding.due_next_month", label: "Due Next Month" },
    { key: "outstanding.due_next_year", label: "Due Next Year" },
    { key: "outstanding.due_beyond_next_year", label: "Beyond Next Year" }
];

// Assigns CSS classes for group highlighting
export const getRepClass = (repId) => {
    if (["8", "22", "51"].includes(repId)) return "priority-rep";
    if (["64", "73"].includes(repId)) return "secondary-rep";
    return "other-rep";
};

