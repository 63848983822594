import React, { Component } from 'react';
import { getSQLData } from '../../../assets/functions/fetch';
import { whichRep } from '../../../assets/functions/calculations';
import moment from 'moment';
import { getMetricRows, getOrderedRepIds, getRepClass, processFollowUpsData } from './fuExports';
import "./fu.css"

class FollowUpReport extends Component {
    constructor(props) {
            super(props);
            this.state = {
                loaded: false,
            };
    

          }
    
            componentDidMount() {
                this.startTime = performance.now(); 
                this.fetchData();
            }
          
            fetchData = async () => {
                let rep = this.state.rep
          
              try {
    
                const fetchFollowups = await getSQLData("sales_operations/FUReport", {})

                
                if(fetchFollowups){
          
                  let followups = fetchFollowups.data.data[0].recordset                              
                  
                  this.setState({
                    followups: followups,
                  }, () => this.processData())
                }
                   
              } catch (error) {
                console.error(error);
              }
            }
    
    
            processData() {
                let followups = this.state.followups
                const metrics = processFollowUpsData(followups)
                const startTime = performance.now();

                this.setState({
                    metrics: metrics,
                    loaded: true
                }, () => console.log(performance.now() - this.startTime));
            }
            render() {
                const { loaded, metrics } = this.state;
        
                if (!loaded || !metrics || Object.keys(metrics).length === 0) {
                    return <p>Loading metrics...</p>;
                }
        
                // Get ordered reps and metric rows
                const orderedRepIds = getOrderedRepIds(metrics);
                const metricRows = getMetricRows();
                const repNames = orderedRepIds.map(repId => metrics[repId]?.repName || "Unknown");
        
                return (
                    <div className="metrics-table-container">
                        <h2>Rep Follow-up Metrics</h2>
                        <table className="metrics-table">
                            <thead>
                                <tr>
                                    <th>Metric</th>
                                    {repNames.map((name, index) => (
                                        <th key={orderedRepIds[index]} className={getRepClass(orderedRepIds[index])}>
                                            {name}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {metricRows.map(({ key, label }) => (
                                    <tr key={key}>
                                        <td>{label}</td>
                                        {orderedRepIds.map(repId => {
                                            const [category, metric] = key.split(".");
                                            return (
                                                <td key={`${repId}-${key}`}>
                                                    {metrics[repId]?.followups?.[category]?.[metric] || 0}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                );
            }
        
            
            
}

export default FollowUpReport;