import moment from "moment"

export function percentTwoDecimal(value){return Math.round(value*10000)/100+'%'}

export function daysLate(complete, target){
    let diff = moment(complete).diff(target,'days')
    if(diff>1000){return ""}
    if(diff>1 && diff<=1000){return diff + " days late"}
    if(diff==1){return "1 day late"}
    if(diff==0){return "On Time"}
    if(diff==-1){return "1 day early"}
    if(diff<-1){return -diff + " days early"}
}

export function numberOfDays(number){
    if(number == 1){return number + " day"}else{return number + " days"}
}

export function formatAbbreviatedDollar(number){
    let formattedNumber = ""
    number = parseInt(number)
    if(number < 1000000){
        formattedNumber = "$" + number.toLocaleString()
    } else if(number < 1000000000) { 
        formattedNumber = "$" + (number/1000000).toLocaleString() + " M"
    } else if(number < 1000000000000) {
        formattedNumber = "$" + (number/1000000000).toLocaleString() + " B"
    } else {
        formattedNumber = "$" + (number/1000000000000).toLocaleString() + " T"
    }

    return formattedNumber
}

export function getPhaseColor(phase){
    switch(phase){
        case "No contact made":
            return "#DADADA"; // Light Gray (Neutral, no movement yet)
        case "Needs info to quote":
            return "#E3D5A8"; // Soft Beige (Waiting on details, mild urgency)
        case "DocuSign sent":
            return "#B8C7DC"; // Muted Blue (In progress, waiting on client)
        case "Revision requested":
            return "#D1A89F"; // Gentle Coral (Needs attention, action required)
        case "DocuSign complete":
            return "#B4C9B0"; // Muted Sage Green (Signed, progressing)
        case "Rental logistics":
            return "#C7D7B8"; // Pale Pastel Green (Coordinating delivery, movement)
        case "Rebill/Add-on":
            return "#AEBF92"; // Soft Olive Green (Extended rental, continuation)           
        case "Post rental follow up":
            return "#A9A0B8"; // Muted Lavender (Checking in, relationship building)
        default:
            return "";    
    }
}