import moment from 'moment';
import React, { Component } from 'react';
import { CustomDateWrapper, Input } from '../../styled/ui';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class RepFollowups extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewing: 'followup',
            hide: {},
        };

      }

    render() {
        let followups = this.props.followups
        let updateList = this.props.updateList
        let selectedFollowupIndex = this.props.selectedFollowupIndex
        let selectedFollowup = (selectedFollowupIndex >= 0) ? followups[selectedFollowupIndex] : {}
        let metrics = this.props.metrics

        let busDays2 = moment().utc().add(2, "days").endOf("day");
        let busDays3 = moment().utc().add(3, "days").endOf("day");
        let busDays4 = moment().utc().add(4, "days").endOf("day");
        let busDays5 = moment().utc().add(5, "days").endOf("day");

        let hide = this.state.hide

        return (


                <>
                <div>
                
                <div style={{display: 'inline-block', backgroundColor: this.props.getPhaseColor("No contact made")}}>No contact made</div>
                <div style={{display: 'inline-block', backgroundColor: this.props.getPhaseColor("Needs info to quote")}}>Needs info to quote</div>
                <div style={{display: 'inline-block', backgroundColor: this.props.getPhaseColor("DocuSign sent")}}>DocuSign sent</div>
                <div style={{display: 'inline-block', backgroundColor: this.props.getPhaseColor("Revision requested")}}>Revision requested</div>
                <div style={{display: 'inline-block', backgroundColor: this.props.getPhaseColor("DocuSign complete")}}>DocuSign complete</div>
                <div style={{display: 'inline-block', backgroundColor: this.props.getPhaseColor("Rental logistics")}}>Rental logistics</div>
                <div style={{display: 'inline-block', backgroundColor: this.props.getPhaseColor("Post rental follow up")}}>Post rental follow up</div>
                </div>
                    <div style={{ display: 'inline-block', width: '50%', border: '1px solid black', height: '800px', overflowY: 'auto', verticalAlign: 'top'}}>
                        {followups.map((followup, index) => (
                            <>
                            {!hide[followup.quote?.phase] && (

                            
                            <div key={index} style={{ padding: '5px', border: '1px solid black', backgroundColor: this.props.getPhaseColor(followup.quote?.phase)  }} onClick={()=>this.props.selectFollowup(index)}>
                                <div style={{ display: 'inline-block', width: '10%' }}>
                                    <Link target="_blank" to={`/quote/${followup.nQuote}`}>
                                    {followup.nQuote}
                                    </Link></div>
                                <div style={{ display: 'inline-block', width: '30%' }}>
                                    {followup.quote?.sName} <br /> 
                                    {followup.quote?.sPhone} {followup.quote?.sEmail}
                                </div>
                                <div style={{ display: 'inline-block', width: '20%' }}>{followup.quote?.sCompany}</div>
                                <div 
                                    style={{ display: 'inline-block', width: '40%' }} 
                                    onClick={()=>navigator.clipboard.writeText(
                                        followup.sNote + " (" + 
                                        followup.quote?.sCompany + " - " + 
                                        followup.quote?.sName + ": " + 
                                        followup.quote?.sPhone + " " + 
                                        followup.quote?.sEmail + ") " + 
                                        "https://cc.cfrinc.us/quote.asp?id=" + followup.nQuote 
                                    )}>
                                    {moment(followup.dtDate).format("MM/DD/YYYY hh:mm A")} <br /> {followup.sNote}
                                </div>
                            </div>
                            )}
                            </>
                        ))
                        }
                    </div>
                    <div style={{ display: 'inline-block', width: '50%', border: '1px solid black', verticalAlign: 'top' }}>
                        {selectedFollowupIndex === -1 && (
                            <div>
                                <table>
                                    <tr>
                                        <td>Followups</td>
                                        <td>{followups.length}</td>
                                    </tr>
                                    <tr>
                                        <td>Late</td>
                                        <td>{metrics.late}</td>
                                    </tr>
                                    <tr>
                                        <td>Today</td>
                                        <td>{metrics.today}</td>
                                    </tr>
                                    <tr>
                                        <td>Tomorrow</td>
                                        <td>{metrics.tomorrow}</td>
                                    </tr>
                                    <tr>
                                        <td>{busDays2.format("dddd")}</td>
                                        <td>{metrics.busDays2}</td>
                                    </tr>
                                    <tr>
                                        <td>{busDays3.format("dddd")}</td>
                                        <td>{metrics.busDays3}</td>
                                    </tr>
                                    <tr>
                                        <td>{busDays4.format("dddd")}</td>
                                        <td>{metrics.busDays4}</td>
                                    </tr>
                                    <tr>
                                        <td>{busDays5.format("dddd")}</td>
                                        <td>{metrics.busDays5}</td>
                                    </tr>
                                    <tr>
                                        <td>This Month</td>
                                        <td>{metrics.thisMonth}</td>
                                    </tr>
                                    <tr>
                                        <td>This Quarter</td>
                                        <td>{metrics.thisQuarter}</td>
                                    </tr>
                                    <tr>
                                        <td>This Year</td>
                                        <td>{metrics.thisYear}</td>
                                    </tr>
                                    <tr>
                                        <td>Next Year</td>
                                        <td>{metrics.nextYear}</td>
                                    </tr>
                                </table>
                            </div>
                        )}
                        {selectedFollowupIndex >= 0 && (
                            <div>
                                <div style={{position: 'relative',}}>
                                    <button onClick={()=>this.setState({selectedFollowup: -1})}>X</button>
                                    <button onClick={()=>this.setState({viewing: 'followup'})}>Follow Up</button>
                                    <button onClick={()=>this.setState({viewing: 'quote'})}>Quote</button>
                                    <button onClick={()=>this.setState({viewing: 'notes'})}>Notes</button>
                                    <button onClick={()=>this.setState({viewing: 'emails'})}>Emails</button>
                                    <button onClick={()=>this.setState({viewing: 'vendor'})}>Vendor</button>
                                </div>
                                <table>
                                    <tr style={{ verticalAlign: 'top',}}>
                                        <td>Followup</td>
                                        <td>
                                    <CustomDateWrapper>
                                        <Input
                                            onChange={(e) => {
                                            this.props.updateData(selectedFollowupIndex, e.target.value, "date");
                                            }}
                                            type="datetime-local"
                                            value={moment(selectedFollowup.dtDate).format("YYYY-MM-DDTHH:mm")}
                                            style={{width:'200px',}}
                                        />
                                    </CustomDateWrapper>
                                            
                                    <textarea style={{width: '200px', height:'100px',}} onChange={(e)=>this.props.updateData(selectedFollowupIndex, e.target.value, "followup")} value={selectedFollowup.sNote} />

                                    {updateList.some(x => x.id === selectedFollowup.id) && <button onClick={()=>this.props.saveFollowupChange(selectedFollowupIndex)}>Update Followup</button>}
                                    </td>
                                    </tr>
                                    <tr>
                                        <td>Note to leave when completing</td>
                                        <td>
                                            <textarea style={{width: '200px', height:'125px',}} onChange={(e)=>this.props.updateData(selectedFollowupIndex, e.target.value, "note")} value={selectedFollowup.sComplete} />

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Next Followup (leave blank if none)</td>
                                        <td>
                                        <CustomDateWrapper>
                                            <Input
                                                onChange={(e) => {
                                                this.props.updateData(selectedFollowupIndex, e.target.value, "next date");
                                                }}
                                                type="datetime-local"
                                                value={selectedFollowup.dtNextDate ? moment(selectedFollowup.dtNextDate).format("YYYY-MM-DDTHH:mm") : ""}
                                                style={{width:'200px',}}
                                            />
                                        </CustomDateWrapper>
                                        
                                <textarea style={{width: '200px', height:'100px',}} onChange={(e)=>this.props.updateData(selectedFollowupIndex, e.target.value, "next followup")} value={selectedFollowup.sNextFollowup}/>
                                
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <b>Complete Followup <br /></b>
                                            <button style={{margin: '5px', padding: '5px', width: '100px'}} onClick={(e)=>this.props.completeFollowup(selectedFollowupIndex)}><i class='fas fa-clock' />&nbsp;0-5m</button>
                                            <button style={{margin: '5px', padding: '5px', width: '100px'}} onClick={(e)=>this.props.completeFollowup(selectedFollowupIndex)}><i class='fas fa-clock' />&nbsp;5-15m</button>
                                            <button style={{margin: '5px', padding: '5px', width: '100px'}} onClick={(e)=>this.props.completeFollowup(selectedFollowupIndex)}><i class='fas fa-clock' />&nbsp;15m-1h</button>
                                            <button style={{margin: '5px', padding: '5px', width: '100px'}} onClick={(e)=>this.props.completeFollowup(selectedFollowupIndex)}><i class='fas fa-clock' />&nbsp;1-2h</button>
                                            <button style={{margin: '5px', padding: '5px', width: '100px'}} onClick={(e)=>this.props.completeFollowup(selectedFollowupIndex)}><i class='fas fa-clock' />&nbsp;2h+</button>
                                        </td>
                                    </tr>
                                </table>
                                <div>
                                    <div>
                                            <div style={{ display: 'inline-block', width: '10%' }}>
                                                {selectedFollowup.nQuote}
                                            </div>
                                            <div style={{ display: 'inline-block', width: '30%' }}>
                                                {selectedFollowup.quote?.sName} <br /> 
                                                {selectedFollowup.quote?.sPhone} {selectedFollowup.quote?.sEmail}
                                            </div>
                                            <div style={{ display: 'inline-block', width: '20%' }}>
                                                {selectedFollowup.quote?.sCompany} {selectedFollowup.quote?.ClosedQuotes} {selectedFollowup.quote?.OpenQuotes} {selectedFollowup.quote?.DiscontinuedQuotes}
                                            </div>
                                            <div style={{ display: 'inline-block', width: '40%' }}>
                                                {selectedFollowup.dtDate} <br /> {selectedFollowup.sNote}
                                            </div>
                                    </div>
                                    <div>
                                            <div style={{display: 'inline-block', width: '50%'}}>
                                                <div>{selectedFollowup.quote?.sAddress}</div>
                                                <div>{selectedFollowup.quote?.sAddress2}</div>
                                                <div>{selectedFollowup.quote?.sCity}, {selectedFollowup.quote?.sState} {selectedFollowup.quote?.sZip}</div>
                                                <div>{moment(selectedFollowup.quote?.dtDeliveryDate).utc().format("MM/DD/YYYY")} - {moment(selectedFollowup.quote?.dtPickupDate).utc().format("MM/DD/YYYY")}</div>
                                            </div>
                                            <div style={{display: 'inline-block', width: '50%'}}>
                                                <table>
                                                    {selectedFollowup.quote?.Lines?.map((line, lineIndex)=>(
                                                        <tr>
                                                            <td>{line.sDescription}</td>
                                                            <td>{line.nQuantity}</td>
                                                            <td>{line.nPrice}</td>
                                                        </tr>
                                                    ))}
                                                </table>
                                            </div>
                                            
                                    </div>
                                    <div>
                                        {selectedFollowup.quote?.RFQs?.map((RFQ, RFQindex)=>(
                                            <div>{RFQ.nMember} {RFQ.dtSubmitted ? moment(RFQ.dtSubmitted).format("MM/DD/YYYY") : ""}</div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                </>
        );
    }
}

export default RepFollowups;