import Axios from 'axios';
import { queryAliases } from '../data/queries';
import { getQueryInfo } from './system';

export async function setSQLData(path, params) {
  console.log("Executing SQL with params:", params); // Log the params to confirm

  try {
    const response = await Axios.post(
      "/set",
      {
        path: path, params: params
      },
    );
    if(response){
      response.query = getQueryInfo(path)
      return response
    }
  } catch (error) {
    console.error("Error in setSQLData:", error);
  }
};

export async function getSQLData(path, params) {
  let alias = queryAliases.find(record => record.alias == path)
  if(alias){path = alias.path}

  try {
    const response = await Axios.get("/fetch", {
      params: {path: path, params: params},
      headers: { 'Content-Type': 'application/json' },
    });

    if(response) {
      response.query = getQueryInfo(path)
      return response
    }

  } catch (error) {
    console.error(error);
  }
};



export async function fetchData(path, params) {
  try {
    const fetch = await getSQLData(path, params)
    
    if(fetch){

      let data = fetch.data.data[0].recordset    
      let query = fetch.query
      
      return {
        data: data,
        query: query,
      }
    }
    
    
  } catch (error) {
    console.error(error);
  }
}